import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

const render = () => {
  import(`./assets/css/sass/themes/gogo.light.blueyale.scss`).then(() => {
    require('./AppRenderer');
  });
};
render();

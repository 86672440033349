import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { NotificationContainer } from "./components/common/react-notifications";
import { LoadableLogin, LoadableDashboard } from "./views/LazyLoad";
import { LoadableApplications } from "./views/LazyLoad";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

const App = () => {
  return (
    <div className="h-100">
      <NotificationContainer />
      <Router>
        <Routes>
          <Route exact path="/" element={ <Navigate to="/applications" replace /> } />
          <Route path="/dashboard" element={ <PrivateRoute> <LoadableDashboard /> </PrivateRoute> } />
          <Route path="/applications/*" element={ <PrivateRoute> <LoadableApplications /> </PrivateRoute> } />
          <Route path="/login" element={<LoadableLogin />} />
          <Route path="/*" element={ <Navigate to="/" replace /> } />
        </Routes>
      </Router>
    </div>
  );
};

export default App;

import React from "react";
import Loadable from 'react-loadable';

const LoadingComponent = () => <div className="loading">.</div>

const LoadableDashboard = Loadable({
    loader: () => import("./Dashboard"),
    loading: LoadingComponent,
});

const LoadableLogin = Loadable({
    loader: () => import("./Login"),
    loading: LoadingComponent,
});

const LoadableApplications = Loadable({
    loader: () => import("./applications"),
    loading: LoadingComponent,
});

export { LoadableDashboard, LoadableLogin, LoadableApplications };
